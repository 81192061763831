import { capitalize } from "./conversions";

export type ReplacedContent = Partial<{
    name?: string;
    policy?: string;
    age?: string;
    gender?: string;
    productName?: string;
}>;

/**
 * Replaces the specified content found within double square brackets
 * @param replacedContent - An object with keys matching the name of the template placeholders
 * @param content - The template containing placeholders (ex. [[NAME]])
 * @example contentReplacer({ name: "Marshal" }, "Hi, my name is [[NAME]]")
 */
export const contentReplacer = (replacedContent: ReplacedContent = {}, content: string = "") => {
    let newContent = content;
    for (const [contentTitle, value] of Object.entries(replacedContent)) {
        if (contentTitle === "name" && value) {
            newContent = newContent.replace(`[[${contentTitle.toUpperCase()}]]`, capitalize(value.toLowerCase()));
        } else {
            newContent = newContent.replace(`[[${contentTitle.toUpperCase()}]]`, value);
        }
    }
    return newContent;
};
